import { render, staticRenderFns } from "./TestComponent.vue?vue&type=template&id=092b4244&scoped=true"
import script from "./TestComponent.vue?vue&type=script&lang=js"
export * from "./TestComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "092b4244",
  null
  
)

export default component.exports